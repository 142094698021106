<template>
  <div class="item-content">
    <div class="d-flex align-items-center justify-content-center">
        <div class="not-empty d-flex flex-column align-items-center pt-1">
            <div class="mb-4">
              <img src="@/assets/icon/empty.png" alt="">
            </div>
            <div class="fs-18 fw-700 color-neutral-900">{{ title ?? 'Kosong...' }}</div>
            <div class="w-75 text-center">
              <p class="fw-400 color-neutral-600">{{ message }}</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message', 'title']
}
</script>
