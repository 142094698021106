<template>
  <div class="container">
    <app-bar
      title="Daftar KKP"
      :previousLink="{ name: 'MCUDetail', params: {kode: mcu.id_mcu} }" />

      <Loading v-if="loading.mcu" />
      <Empty v-else-if="listKkp.length === 0" :message="'Saat ini belum ada KKP untuk ditampilkan.'"/>
      <div v-else class="row">
          <div class="col-md-7">
              <div v-for="kkp in listKkp.kkp" :key="kkp.kkp" class="card-info d-flex flex-column px-3 pb-3 mb-3">
                  <div class="d-flex flex-row py-3 align-items-center">
                    <div :id="'barcode-'+kkp.kkp" class="me-3"></div>
                    <div class="d-flex flex-column gap-2">
                      <span class="fs-14 fw-700 color-neutral-900">{{ kkp.nama }}</span>
                      <div
                        v-if="kkp.status === 0"
                        class="badge fs-12 fw-600 color-danger-600 bg-danger-100"
                        style="border-radius: 100px">
                          Belum diperiksa
                      </div>
                      <div
                        v-else-if="kkp.status === 1"
                        class="badge fs-12 fw-600 color-warning-600 bg-warning-100"
                        style="border-radius: 100px">
                          Mulai diperiksa
                      </div>
                      <div
                        v-else-if="kkp.status === 2"
                        class="badge fs-12 fw-600 color-primary-600 bg-primary-100"
                        style="border-radius: 100px">
                          Selesai diperiksa
                      </div>
                    </div>
                  </div>
                  <router-link
                    :to="{
                      name: 'MCUDetailKkp',
                      params: {
                        kode: mcu.id_mcu,
                        kkp: kkp.kkp
                      }
                    }"
                    class="btn btn-primary btn-login w-100">
                      <span class="">Lihat QR Code</span>
                  </router-link>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue'
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import AppBar from '@/components/AppBar.vue'
import { user } from '@/core/auth'
import { mcu, getListKkp, loading } from '@/core/mcu'
import { qrcanvas } from 'qrcanvas'

export default {
  components: { AppBar, Loading, Empty },
  setup () {
    const listKkp = ref([])

    const generateQr = () => {
      for (let i = 0; i < listKkp.value.kkp.length; i++) {
        const barcode = document.getElementById('barcode-' + listKkp.value.kkp[i].kkp)

        const canvasSmall = qrcanvas({
          cellSize: 3,
          data: listKkp.value.kkp[i].kkp,
          foreground: [
            { style: '#007A98' }
          ]
        })

        barcode.appendChild(canvasSmall)
      }
    }

    onMounted(async () => {
      const result = await getListKkp({
        id_pendaftar: user.value.id,
        id_mcu: mcu.value.id_mcu
      })

      listKkp.value = result.data

      nextTick(() => {
        generateQr()
      })
    })

    const refreshData = async () => {
      const result = await getListKkp({
        id_pendaftar: user.value.id,
        id_mcu: mcu.value.id_mcu
      })

      listKkp.value = result.data
      generateQr()
    }

    return {
      mcu,
      listKkp,
      loading,
      refreshData
    }
  }
}
</script>

<style lang="scss" scoped>
.foto-selfie {
  border-radius: 100px;
  width: 84px;
  height: 84px;
}

.btn-login {
  border: none;
  padding: 10px 20px;
}

.badge {
  padding: 5px 10px;
}

.card-info {
  border: 1px solid #EDF2F7;
  box-sizing: border-box;
  border-radius: 14px;
}

.modal-dialog {
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100vh;
}

.location-info .card-info {
    background: #F7F7FC;
}

.icon-bg {
    width: 2.5rem;
    border-radius: 8px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.bottom-bar {
  @media (max-width: 520px) {
    background: unset;
    position: absolute;
    bottom: 3%;
    left: 0;
    width: 100%;
    border: 0;
  }

  img {
    width: 50px;
  }
}

.card.summary-order {
  border: 1px solid #EEEFF7;
  box-sizing: border-box;
  border-radius: 10px;
}

.page-title {
   @media (max-width: 520px) {
    padding-top: 0.75rem;
  }
}
</style>
